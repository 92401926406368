/* Messages */
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_ERRORS = "GET_ERRORS";
/* News */
export const NEWS_LISTING = "NEWS_LISTING";
export const NEWS_LISTED = "NEWS_LISTED";
export const NEWS_FAIL = "NEWS_FAIL";
/* Update - dates */
export const UPDATES_LISTING = "UPDATES_LISTING";
export const UPDATES_LISTED = "UPDATES_LISTED";
export const UPDATES_FAIL = "UPDATES_FAIL";
/* Email */
export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_FAIL = "EMAIL_FAIL";
/* Mobiles */
export const MOBILES_LISTING = "MOBILES_LISTING";
export const MOBILES_LISTED = "MOBILES_LISTED";
export const MOBILES_FAIL = "MOBILES_FAIL";
/* Electronics */
export const ELECTRONICS_LISTING = "ELECTRONICS_LISTING";
export const ELECTRONICS_LISTED = "ELECTRONICS_LISTED";
export const ELECTRONICS_FAIL = "ELECTRONICS_FAIL";
/* Cosmetics */
export const COSMETICS_LISTING = "COSMETICS_LISTING";
export const COSMETICS_LISTED = "COSMETICS_LISTED";
export const COSMETICS_FAIL = "COSMETICS_FAIL";
/* Medicines */
export const MEDICINES_LISTING = "MEDICINES_LISTING";
export const MEDICINES_LISTED = "MEDICINES_LISTED";
export const MEDICINES_FAIL = "MEDICINES_FAIL";
/* Alcohols */
export const ALCOHOLS_LISTING = "ALCOHOLS_LISTING";
export const ALCOHOLS_LISTED = "ALCOHOLS_LISTED";
export const ALCOHOLS_FAIL = "ALCOHOLS_FAIL";
/* Users */
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
