export const tokenConfig = (getState, hasFile = false) => {
  // Get token from state
  const token = getState().accounts.token;
  // Headers
  const contentType = hasFile ? "multipart/form-data" : "application/json";
  const config = {
    headers: {
      "Content-Type": contentType,
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

export const getFormData = (data, fileKeys = ["id_front", "id_back"]) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    let entryData = data[key];
    if (fileKeys.includes(key) && entryData) {
      if (entryData instanceof File) {
        formData.append(key, entryData);
      }
    } else {
      formData.append(key, entryData);
    }
  });
  return formData;
};
