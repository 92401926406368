import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

const StepHeader = ({ content }) => (
  <Header
    as="h3"
    icon="check square outline"
    content={content}
    block
    inverted
  />
);

StepHeader.propTypes = {
  content: PropTypes.string.isRequired,
};

export default StepHeader;
