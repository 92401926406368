import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listNews, listDates } from "../redux/actions/news";
import { Grid, Container, Button } from "semantic-ui-react";
import DownloadButtons from "../components/DownloadButtons";
import SocialBar from "./SocialBar";
import Instruction from "./Instruction";
import MyMessage from "./MyMessage";
import Loading from "./Loading";
import DateTag from "./DateTag";
import QueryBox from "./QueryBox";

const pages = ["mobile", "electronics", "cosmetics", "medicine", "alcohol"];

const findPageInfo = (info, page) => {
  if (info) return info.find((i) => i.page === page);
  return null;
};

const BasicView = ({
  /* Redux */
  listNews,
  news,
  newsListing,
  listDates,
  dates,
  datesListing,
  /* Props */
  pageIdx,
  kaitori,
  itemListing,
  nodeSearch,
  nodeMenu,
  nodeBoard,
  queryProps,
}) => {
  useEffect(listNews, []);
  useEffect(listDates, []);

  const [showAll, toggleDisplay] = useState(false);

  const universalNews = findPageInfo(news, "universal");
  const globalNews = findPageInfo(news, "global");
  const localNews = findPageInfo(news, pages[pageIdx]);
  const updateDate = findPageInfo(dates, pages[pageIdx]);

  return (
    <>
      {/* <Banners /> */}
      <Grid stackable>
        {newsListing || datesListing || itemListing ? (
          <Grid.Column width="16">
            <Loading />
          </Grid.Column>
        ) : (
          <>
            <Grid.Column width="4">
              <QueryBox {...queryProps} />
              {nodeSearch}
              <DownloadButtons kaitori={kaitori} />
              {nodeMenu}
              <SocialBar />
            </Grid.Column>
            <Grid.Column width="12">
              <MyMessage newsObject={universalNews} />
              <MyMessage newsObject={globalNews} />
              <MyMessage newsObject={localNews} />

              <DateTag date={updateDate} />
              <div style={{ marginTop: "5px" }}>
                <Button
                  icon={showAll ? "caret up" : "caret down"}
                  onClick={() => toggleDisplay(!showAll)}
                  size="tiny"
                  basic
                />
              </div>
              <div className={showAll ? null : "scroll-div"}>
                <Container style={{ padding: "0 19px 0 1px" }}>
                  {nodeBoard}
                </Container>
              </div>
            </Grid.Column>
          </>
        )}
      </Grid>
    </>
  );
};

BasicView.propTypes = {
  pageIdx: PropTypes.number.isRequired,
  kaitori: PropTypes.string.isRequired,
  listNews: PropTypes.func.isRequired,
  news: PropTypes.array.isRequired,
  newsListing: PropTypes.bool.isRequired,
  listDates: PropTypes.func.isRequired,
  dates: PropTypes.array.isRequired,
  datesListing: PropTypes.bool.isRequired,
  itemListing: PropTypes.bool.isRequired,
  nodeSearch: PropTypes.node.isRequired,
  nodeMenu: PropTypes.node.isRequired,
  nodeBoard: PropTypes.node.isRequired,
  queryProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  news: state.news.news,
  newsListing: state.news.newsListing,
  dates: state.news.dates,
  datesListing: state.news.datesListing,
});

export default connect(mapStateToProps, {
  listNews,
  listDates,
})(BasicView);
