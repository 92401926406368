import React from "react";
import PropTypes from "prop-types";
import { Label, Menu } from "semantic-ui-react";

const SideMenu = ({ activeIdx, setIdx, type = "FLAT", items, num0 = 0 }) => {
  return (
    <Menu vertical fluid>
      <Menu.Item active={activeIdx === -1} onClick={() => setIdx(-1)}>
        <Label color={activeIdx === -1 ? "red" : null}>{num0}</Label>
        強化買取
      </Menu.Item>
      {type === "FLAT"
        ? items.map((item, idx) => (
            <Menu.Item
              key={item.id}
              active={activeIdx === idx}
              onClick={() => setIdx(idx)}
            >
              <Label color={activeIdx === idx ? "red" : null}>{item.num}</Label>
              {item.text}
            </Menu.Item>
          ))
        : items.map((item) => (
            <Menu.Item key={item.id}>
              {item.text}
              <Menu.Menu>
                {item.sub.map((i) => (
                  <Menu.Item
                    key={i.id}
                    active={activeIdx === i.id}
                    onClick={() => setIdx(i.id)}
                  >
                    <Label color={activeIdx === i.id ? "red" : null}>
                      {i.num}
                    </Label>
                    {i.text}
                  </Menu.Item>
                ))}
              </Menu.Menu>
            </Menu.Item>
          ))}
    </Menu>
  );
};

SideMenu.propTypes = {
  activeIdx: PropTypes.number.isRequired,
  setIdx: PropTypes.func.isRequired,
  type: PropTypes.string,
  items: PropTypes.array.isRequired,
  num0: PropTypes.number,
};

export default SideMenu;
