import React from "react";
import { Advertisement } from "semantic-ui-react";
import AdSense from "react-adsense";

const MyAd = () => (
  <Advertisement unit="small rectangle" centered>
    <AdSense.Google
      client="ca-pub-3660806626391543"
      format=""
      slot="2782217729"
      style={{ display: "inline-block", width: "150px", height: "300px" }}
    />
  </Advertisement>
);

export default MyAd;
