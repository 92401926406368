import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleClick = (name, value) => setValues({ ...values, [name]: value });

  const clearAll = () => setValues(initialValues);

  return [values, handleChange, handleClick, clearAll];
};

export const useError = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const checkErrors = (values) => {
    let temp = {};
    Object.keys(initialValues).forEach((k) => {
      temp[k] = !Boolean(values[k]);
    });
    if ("re_password" in values) {
      temp.re_password = values.password !== values.re_password;
    }
    const res = { ...initialValues, ...temp };
    setValues(res);
    return Object.keys(res).some((k) => res[k]);
  };

  return [values, checkErrors];
};
