import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Divider } from "semantic-ui-react";

const InfoView = ({ header, children }) => (
  <Segment color="red">
    <Header as="h3" content={header} />
    <Divider />
    {children}
  </Segment>
);

InfoView.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfoView;
