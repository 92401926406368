import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
} from "../common/types";

const tokenName = "lala-customer-token";
const initialState = {
  token: localStorage.getItem(tokenName),
  authenticated: false,
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem(tokenName, action.payload.token);
      console.log(localStorage.getItem(tokenName));
      return {
        ...state,
        ...action.payload,
        authenticated: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem(tokenName);
      return {
        ...initialState,
        token: null,
      };
    default:
      return state;
  }
}
