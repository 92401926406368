import {
  HOME,
  ELECTRONICS,
  COSMETICS,
  WAREHOUSE,
  MEDICINE,
  ALCOHOL,
} from "./urls";

export const INIT_PWD = "init_password_2020";
export const BG_MOBILE = process.env.PUBLIC_URL + "/bg-mobile.jpg";
export const BG_ELECTRONICS = process.env.PUBLIC_URL + "/bg-electronics.jpg";
export const BG_COSMETICS = process.env.PUBLIC_URL + "/bg-cosmetics.jpg";
export const BG_WAREHOUSE = process.env.PUBLIC_URL + "/bg-warehouse.jpg";
export const BG_MEDICINE = process.env.PUBLIC_URL + "/bg-medicine.jpg";
export const BG_ALCOHOL = process.env.PUBLIC_URL + "/bg-alcohol.jpg";
export const KAITORI_M = process.env.PUBLIC_URL + "/kaitori_M.pdf";
export const KAITORI_C = process.env.PUBLIC_URL + "/kaitori_C.pdf";
export const AGREEMENT = process.env.PUBLIC_URL + "/agreement.pdf";
export const LINE = process.env.PUBLIC_URL + "/line_qr.png";
export const WECHAT = process.env.PUBLIC_URL + "/wechat_qr.png";
export const NO_IMG = process.env.PUBLIC_URL + "/noImg.png";

export const banners = [
  {
    title: "LALA Mobile",
    description: "迅速査定、高額買取、即現金化",
    subtitle: "ララモバイル携帯買取",
    image: BG_MOBILE,
    url: HOME,
  },
  {
    title: "LALA Mobile",
    description: "迅速査定、高額買取、即現金化",
    subtitle: "ララモバイル家電買取",
    image: BG_ELECTRONICS,
    url: ELECTRONICS,
  },
];

export const banners2 = [
  {
    title: "LALA Shop",
    description: "迅速査定、高額買取、即現金化",
    subtitle: "ララショップ日用品買取",
    image: BG_COSMETICS,
    url: COSMETICS,
  },
  {
    title: "LALA Shop",
    description: "迅速査定、高額買取、即現金化",
    subtitle: "ララショップ医薬品買取",
    image: BG_MEDICINE,
    url: MEDICINE,
  },
  {
    title: "LALA Shop",
    description: "迅速査定、高額買取、即現金化",
    subtitle: "ララショップお酒買取",
    image: BG_ALCOHOL,
    url: ALCOHOL,
  },
];

export const warehouseBanner = {
  title: "LALA Shop",
  description: "迅速査定、高額買取、即現金化",
  subtitle: "ララショップ中古品",
  image: BG_WAREHOUSE,
  url: WAREHOUSE,
};

export const colors = [
  { key: "red", code: "RED", hex: "#db2828" },
  { key: "orange", code: "ORANGE", hex: "#f2711c" },
  { key: "yellow", code: "YELLOW", hex: "#fbbd08" },
  { key: "olive", code: "OLIVE", hex: "#b5cc18" },
  { key: "green", code: "GREEN", hex: "#21ba45" },
  { key: "teal", code: "TEAL", hex: "#00b5ad" },
  { key: "blue", code: "BLUE", hex: "#2185d0" },
  { key: "violet", code: "VOILET", hex: "#6435c9" },
  { key: "purple", code: "PURPLE", hex: "#a333c8" },
  { key: "pink", code: "PINK", hex: "#e03997" },
  { key: "brown", code: "BROWN", hex: "#a5673f" },
  { key: "grey", code: "GREY", hex: "#767676" },
  { key: "black", code: "BLACK", hex: "#000" },
];

export const sizes = [
  { key: "small", code: "SMALL", size: ".92857143em", text: "小" },
  { key: "medium", code: "MEDIUM", size: "1em", text: "中" },
  { key: "large", code: "LARGE", size: "1.14285714em", text: "大" },
  { key: "huge", code: "HUGE", size: "1.42857143em", text: "超大" },
  { key: "massive", code: "MASSIVE", size: "1.71428571em", text: "巨大" },
];

export const prefectures = [
  { key: "Hokkaido", text: "北海道", value: "Hokkaido" },
  { key: "Aomori", text: "青森県", value: "Aomori" },
  { key: "Iwate", text: "岩手県", value: "Iwate" },
  { key: "Miyagi", text: "宮城県", value: "Miyagi" },
  { key: "Akita", text: "秋田県", value: "Akita" },
  { key: "Yamagata", text: "山形県", value: "Yamagata" },
  { key: "Fukushima", text: "福島県", value: "Fukushima" },
  { key: "Ibaraki", text: "茨城県", value: "Ibaraki" },
  { key: "Tochigi", text: "栃木県", value: "Tochigi" },
  { key: "Gunma", text: "群馬県", value: "Gunma" },
  { key: "Saitama", text: "埼玉県", value: "Saitama" },
  { key: "Chiba", text: "千葉県", value: "Chiba" },
  { key: "Tokyo", text: "東京都", value: "Tokyo" },
  { key: "Kanagawa", text: "神奈川県", value: "Kanagawa" },
  { key: "Niigata", text: "新潟県", value: "Niigata" },
  { key: "Toyama", text: "富山県", value: "Toyama" },
  { key: "Ishikawa", text: "石川県", value: "Ishikawa" },
  { key: "Fukui", text: "福井県", value: "Fukui" },
  { key: "Yamanashi", text: "山梨県", value: "Yamanashi" },
  { key: "Nagano", text: "長野県", value: "Nagano" },
  { key: "Gifu", text: "岐阜県", value: "Gifu" },
  { key: "Shizuoka", text: "静岡県", value: "Shizuoka" },
  { key: "Aichi", text: "愛知県", value: "Aichi" },
  { key: "Mie", text: "三重県", value: "Mie" },
  { key: "Shiga", text: "滋賀県", value: "Shiga" },
  { key: "Kyoto", text: "京都府", value: "Kyoto" },
  { key: "Osaka", text: "大阪府", value: "Osaka" },
  { key: "Hyogo", text: "兵庫県", value: "Hyogo" },
  { key: "Nara", text: "奈良県", value: "Nara" },
  { key: "Wakayama", text: "和歌山県", value: "Wakayama" },
  { key: "Tottori", text: "鳥取県", value: "Tottori" },
  { key: "Shimane", text: "島根県", value: "Shimane" },
  { key: "Okayama", text: "岡山県", value: "Okayama" },
  { key: "Hiroshima", text: "広島県", value: "Hiroshima" },
  { key: "Yamaguchi", text: "山口県", value: "Yamaguchi" },
  { key: "Tokushima", text: "徳島県", value: "Tokushima" },
  { key: "Kagawa", text: "香川県", value: "Kagawa" },
  { key: "Ehime", text: "愛媛県", value: "Ehime" },
  { key: "Kochi", text: "高知県", value: "Kochi" },
  { key: "Fukuoka", text: "福岡県", value: "Fukuoka" },
  { key: "Saga", text: "佐賀県", value: "Saga" },
  { key: "Nagasaki", text: "長崎県", value: "Nagasaki" },
  { key: "Kumamoto", text: "熊本県", value: "Kumamoto" },
  { key: "Oita", text: "大分県", value: "Oita" },
  { key: "Miyazaki", text: "宮崎県", value: "Miyazaki" },
  { key: "Kagoshima", text: "鹿児島県", value: "Kagoshima" },
  { key: "Okinawa", text: "沖縄県", value: "Okinawa" },
];
