import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import SmallButton from "./SmallButton";

const FileInput = ({ name, label, placeholder, onChange, value, error }) => {
  const inputRef = useRef();
  const cloneFile = (f) => new File([f], f.name);

  const readUrl = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
  };

  return (
    <Form.Field required error={error}>
      <label>{label}</label>
      <input
        type="file"
        ref={inputRef}
        name={name}
        onChange={(e) => {
          if (e.target.files[0]) {
            onChange(name, cloneFile(e.target.files[0]));
            readUrl(cloneFile(e.target.files[0]));
            e.target.value = "";
          }
        }}
        hidden
        accept="image/*"
      />
      <SmallButton
        content={value ? "OK" : placeholder}
        icon={value ? "check" : "upload"}
        color={value ? "green" : "grey"}
        onClick={() => inputRef.current.click()}
      />
      <SmallButton
        content="リセット"
        icon="eraser"
        color="yellow"
        onClick={() => onChange(name, "")}
      />
    </Form.Field>
  );
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
};

export default FileInput;
