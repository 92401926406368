import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listMedicines } from "../redux/actions/products";
import BasicView from "../components/BasicView";
import { KAITORI_C } from "../common/constants";
import { getFlatArr, getFiltered } from "../common/utils";
import SearchBox from "../components/SearchBox";
import SideMenu from "../components/SideMenu";
import ScrollList from "../components/ScrollList";

const Medicine = ({ medicines, medicinesListing, listMedicines }) => {
  useEffect(listMedicines, []);
  const [activeIdx, setIdx] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const arr = getFlatArr(medicines, 2);
  const menuItems = medicines.map((b) => ({
    id: b.id,
    text: b.name,
    num: b.products.length,
  }));
  const recommended = arr.filter((p) => p.recommended);

  return (
    <BasicView
      pageIdx={3}
      kaitori={KAITORI_C}
      itemListing={medicinesListing}
      queryProps={{ levels: 2, tree: medicines }}
      nodeSearch={
        <SearchBox
          clickEvent={() => setIdx(-2)}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      }
      nodeMenu={
        <SideMenu
          activeIdx={activeIdx}
          setIdx={setIdx}
          num0={recommended.length}
          items={menuItems}
        />
      }
      nodeBoard={
        activeIdx === -2 ? (
          <ScrollList
            header="商品検索"
            body={getFiltered(arr, keyword)}
            icon="search"
            empty="ご指定の検索条件に該当する商品はみつかりませんでした"
          />
        ) : activeIdx === -1 ? (
          <ScrollList header="強化買取" body={recommended} icon="thumbs up" />
        ) : (
          <ScrollList
            header={medicines[activeIdx].name}
            body={medicines[activeIdx].products}
            icon="medkit"
          />
        )
      }
    />
  );
};

Medicine.propTypes = {
  listMedicines: PropTypes.func.isRequired,
  medicines: PropTypes.array.isRequired,
  medicinesListing: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  medicines: state.products.medicines,
  medicinesListing: state.products.medicinesListing,
});

export default connect(mapStateToProps, { listMedicines })(Medicine);
