import React from "react";
import InfoView from "../components/InfoView";
import { Message, Segment, Header } from "semantic-ui-react";

const Organization = () => (
  <InfoView header="法人買取">
    <Message
      negative
      list={[
        "法人様でこんな悩みは御座いませんか？",
        "機器の一斉入れ替えによる、旧型機器を売却したい。",
        "大量にあまった在庫を処分したい。",
        "倉庫に眠っている余剰在庫商品を整理したい。",
        "小ロットから大ロットまで一括で在庫の処分を行いたい。",
      ]}
    />
    <Segment>
      <Header
        as="h4"
        icon="check circle outline"
        content="不要品は現金化可能！"
      />
      <p>
        システムの一斉入れ替えによる新製品のご購入時など、今までお使い頂いていた機器が不要になるケースがあるかと思います。
        <br />
        不要になった機器の有料廃棄をご検討されたり、いずれ出番があると考え倉庫に放置されたりと、本来資産であり現金化可能なものが只々損失となっている企業様が多い現状で御座います。
      </p>
      <Header
        as="h4"
        icon="check circle outline"
        content="どのような機器でも幅広く買取可能！"
      />
      <p>
        法人様でご不要な携帯電話、スマートフォンをはじめとする電子機器がございましたら是非一度、無料査定だけでもさせていただければと思います。
        <br />
        既に使わなくなった機器が何台かある、そろそろ新しい機器をご購入されるという企業様が御座いましたら、どのような機器でも幅広く買取りさせて頂きます。買取件数月間10000台以上の実績を元に、当店の法人専任スタッフが迅速・丁寧に対応致します。
      </p>
      <Header
        as="h4"
        icon="check circle outline"
        content="多数の販路により利益の大幅還元が可能！"
      />
      <p>
        適正で高額な買取を実現するために、弊社ではリアルタイムの買取相場と過去の買取履歴を元にして、買取査定額をご提示します。商品の状態（キズ・汚れなど）まで事前にお電話でしっかりとヒアリングし査定額に納得して頂いた上で、お取引を開始することが可能です。
        <br />
        また、国内外問わず多数の販路があり、携帯電話のリユース業界売上No.1の実績が御座います。確実に販売が出来るからこそ、お取引様へ利益の大幅還元が可能となっております。
      </p>
    </Segment>
  </InfoView>
);

export default Organization;
