import { authenticate, unsettle, upload, retrieve } from "../common/requests";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../common/types";

export const login = (username, password) =>
  authenticate("/api/customer/login", LOGIN_SUCCESS, LOGIN_FAIL, {
    username,
    password,
  });

export const logout = () =>
  unsettle("/api/auth/logout-all", LOGOUT_SUCCESS, null);

export const loadUser = () =>
  retrieve("/api/customer/user", USER_LOADED, AUTH_ERROR);

export const signUp = (userInfo) =>
  upload("/api/customer/sign-up", userInfo, REGISTER_SUCCESS, REGISTER_FAIL);
