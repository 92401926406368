import React from "react";

const src =
  "https://maps.google.com/maps?q=%E6%9D%B1%E4%BA%AC%E9%83%BD%E8%B1%8A%E5%B3%B6%E5%8C%BA%E6%B1%A0%E8%A2%8B1-1-8&t=&z=13&ie=UTF8&iwloc=&output=embed";
// const src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.6919191832544!2d139.70935201529392!3d35.73379463469478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d5e1e1decbb%3A0x89af6700c55d4a03!2s2-ch%C5%8Dme-48-8%20Ikebukuro%2C%20Toshima%20City%2C%20T%C5%8Dky%C5%8D-to%20171-0014!5e0!3m2!1sen!2sjp!4v1588770377864!5m2!1sen!2sjp"

const Map = () => (
  <iframe
    title="my-map"
    src={src}
    width="100%"
    height="100%"
    frameBorder="0"
    style={{ border: "0" }}
    allowFullScreen=""
    tabIndex="0"
  />
);

export default Map;
