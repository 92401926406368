import React from "react";
import { Card, Label, Item } from "semantic-ui-react";
import { LINE, WECHAT } from "../common/constants";
import { TwitterTimelineEmbed, TwitterFollowButton } from "react-twitter-embed";
import MyAd from "./MyAd";

const SocialBar = () => (
  <Card.Group>
    {/*
    <Card fluid color="red">
      <Image src={BG_WAREHOUSE} wrapped ui={false} />
      <Card.Content textAlign="center">
        <p>ララショップ中古アウトレット</p>
        <Card.Description>
          <Label content="準備中" icon="warehouse" color="red" />
        </Card.Description>
      </Card.Content>
    </Card>
    

    <Card fluid color="red" style={{ padding: "15px" }}>
      <Item.Group divided>
        <Item>
          <Item.Image size="tiny" src={LINE} />
          <Item.Content verticalAlign="middle">
            <Item.Description>
              <p>Line公式アカウント</p>
              <Label content="友達になろう！" icon="linechat" color="red" />
            </Item.Description>
          </Item.Content>
        </Item>
        <Item>
          <Item.Image size="tiny" src={WECHAT} />
          <Item.Content verticalAlign="middle">
            <Item.Description>
              <p>WeChat公式アカウント</p>
              <Label content="友達になろう！" icon="wechat" color="red" />
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </Card>
    */}

    <Card fluid color="red">
      <Card.Content>
        <TwitterFollowButton
          screenName="LALAM0bile"
          options={{
            showCount: false,
            showScreenName: false,
          }}
        />
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="LALAM0bile"
          options={{ height: 400 }}
          lang="ja"
          noHeader
        />
      </Card.Content>
    </Card>

    <Card fluid color="red">
      <Card.Content>
        <MyAd />
      </Card.Content>
    </Card>
  </Card.Group>
);

export default SocialBar;
