import React from "react";
import InfoView from "../components/InfoView";
import { Segment, List, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CONTACT } from "../common/urls";
import BuyInMenu from "../components/BuyInMenu";
import Phone from "../components/Phone";
import Red from "../components/Red";
import { AGREEMENT, KAITORI_C, KAITORI_M } from "../common/constants";
import Highlight from "../components/Highlight";
import StepHeader from "../components/StepHeader";

const props = {
  as: "a",
  target: "_blank",
  rel: "noopener noreferrer",
  color: "red",
  icon: true,
  labelPosition: "left",
};

const Mail = () => (
  <InfoView header="郵送買取">
    <Button {...props} href={KAITORI_M}>
      <Icon name="download" />
      買取申込書（携帯、家電）
    </Button>
    <Button {...props} href={KAITORI_C}>
      <Icon name="download" />
      買取申込書（日用品）
    </Button>
    <Button {...props} href={AGREEMENT}>
      <Icon name="download" />
      保護者同意書
    </Button>
    <Segment>
      <StepHeader content="STEP1 買取金額の確認とお申込み" />
      <List
        bulleted
        items={[
          "まずは サイト内買取商品の参考査定価格を記載しておりますので、そちらをご覧下さい。",
          "大口の場合でも、載ってない商品でも、お気軽にご相談ください。",
          "買取価格が異なる場合がございます。また、買取価格は日々変動いたしますので予めご了承ください。",
        ]}
      />
      <List bulleted>
        <List.Item>
          自動査定は、無料スピード査定　
          <BuyInMenu />
        </List.Item>
        <List.Item>
          下記の方法で郵送買取をご希望の際にはご予約をさせていただきます。
          <List.List>
            <List.Item>
              <Highlight>オンライン予約</Highlight>は、
              <Link to={CONTACT}>買取申込</Link>
              <Red>必要事項を入力フォームにご記入 いただきます。</Red>
            </List.Item>
            <List.Item>
              <Highlight>電話予約</Highlight>は、
              <Phone />
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>営業時間：平日・祝日：10:00 〜 19:00 定休：木曜日</List.Item>
      </List>
      <List bulleted>
        <List.Item>
          ご予約の際には、携帯電話機の<Red>キャリア名・メーカー名・機種名</Red>
          などの「情報」をお知らせ下さ
          い。その「情報」を基に、買取金額をお知らせ致します。
        </List.Item>
        <List.Item>
          ご金額に納得頂きお売りいただく場合は、
          <Red>お名前とお電話番号、詳しい台数等</Red>をお知らせくだ さい。
        </List.Item>
        <List.Item>
          ※ご予約頂いた際の金額は<Red>翌日</Red>到着まで有効です。
        </List.Item>
        <List.Item>
          ※中古商品は状態により買取金額が異なるため<Red>商品到着後の査定</Red>
          となります。
        </List.Item>
      </List>
      <StepHeader content="STEP2 商品の発送" />
      <List bulleted>
        <List.Item>
          携帯電話機を当店にご郵送して頂きます。
          携帯電話機は精密機械ですので、壊れないよう
          に充分な梱包をお願い致します。また発送は<Red>元払い</Red>
          にてお願い致します。
          <List.List>
            <List.Item
              as="a"
              target="__blank"
              href="https://www.kuronekoyamato.co.jp/ytc/customer/members/send/shuka/"
            >
              ヤマト集荷サービス
            </List.Item>
            <List.Item
              as="a"
              target="__blank"
              href="https://www.sagawa-exp.co.jp/send/branch_search/"
            >
              佐川集荷サービス
            </List.Item>
            <List.Item
              as="a"
              target="__blank"
              href="https://mgr.post.japanpost.jp/C20P02Action.do;jsessionid=vYNSbppMgFvtnLCgqkyyWHlzq0hJnJnndqPhVMKdjX7C2dKdFYFy!-162357051?ssoparam=0&termtype=2"
            >
              日本郵便集荷
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          <Red>
            ※ご予約のない方、土・日など当社の休業日に配送いただいた商品に関しましては、商品が当社に到着した時のお値段にて対応させていただきます。
          </Red>
        </List.Item>
      </List>
      <List bulleted>
        <List.Item>
          配送先
          <br />
          〒170-0014 東京都豊島区池袋1-1-8 SKYビル1階 ララショップ 宛て
          <br />
          TEL：03-5956-8558
        </List.Item>
        <List.Item>
          買取に出されるスマホ・タブレットは、必ず初期化(リセット)し、iCloud・Google等の個人アカウントはサインアウトしてください。
        </List.Item>
        <List.Item>
          初期化チェックリストとリセット方法
          <List.List>
            <List.Item>全てのデータを削除</List.Item>
            <List.Item>ICアプリの削除</List.Item>
            <List.Item>暗証番号の初期化</List.Item>
            <List.Item>端末の充電</List.Item>
          </List.List>
        </List.Item>
      </List>
      <StepHeader content="STEP3 査定結果のお知らせ" />
      <List bulleted>
        <List.Item>
          商品が到着致しましたら、到着のご報告をさせていただきます。査定させていただき、査定結果(確定金額)をメールか電話にてお知らせ致します。査定結果をご確認いただき、ご承諾またはご返送を選択いただきます。
        </List.Item>
        <List.Item>
          ご承諾の場合
          <br />
          査定結果メールか電話に承諾の旨を記載の上、ご返信いただきますようお願い致します。
        </List.Item>
        <List.Item>
          ご返送の場合
          <br />
          査定結果メールか電話に返送希望を記載の上、ご返信いただきますようお願い致します。
        </List.Item>
        <List.Item>
          査定結果のご報告は到着日より順次行なわせていただきます。
        </List.Item>
      </List>
      <StepHeader content="STEP4 お支払い（銀行振込　または　現金書留）" />
      <List bulleted>
        <List.Item>
          本人確認書類について
          <List.List>
            <List.Item>
              古物営業法により、買取には公的書類による本人確認、その書類に応じた代金の取引が義務付けられております。
            </List.Item>
            <List.Item>
              書類の種類によって代金の受取方法が異なりますので、ご注意ください。
            </List.Item>
            <List.Item>
              <Red>※現金書留をご希望の場合、手数料1000円かかります。</Red>
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>
          口座への入金の場合（ご本人名義の口座へ入金）
          <br />
          発行より3ヶ月以内のもののみ有効
          <Red>
            <List.List>
              <List.Item>住民票（原本）※マイナンバーの記載のないもの</List.Item>
            </List.List>
          </Red>
          ※上記の原本の内いずれかひとつが必要になります。
          <br />
          ※商品到着後、翌日より3営業日以内に送金します。（土日祝除く）（問題のあった買取除く・繁忙期などは遅れる場合がございます）。
        </List.Item>
        <List.Item>
          現金書留の場合（本人限定受取による現金書留にて送金）
          <Red>
            <List.List>
              <List.Item>運転免許証の写し・・・・表裏</List.Item>
              <List.Item>
                旅券(パスポート)の写し・・・・写真面、住所記載面
              </List.Item>
              <List.Item>国民健康保険被保険者証の写し・・・・表裏</List.Item>
              <List.Item>在留カードの写し・・・・表裏</List.Item>
            </List.List>
          </Red>
          ※上記の書類の内いずれかひとつが必要になります。 <br />
          ※個人番号(マイナンバー)カード、通知カードは、買い取り時の本人確認書類としてご利用いただけません。
          <br />
          ※商品到着後、翌日より3営業日以内に送付します。（土日祝除く）（問題のあった買取除く・繁忙期などは遅れる場合がございます）。
          <br />
          ※現金書留は古物商により転送しない取扱いでの送付限定になります。
          <br />
          【注意】
          現住所の記載のあるもの、有効期限内のもの、住所変更の済んでいるもののみ有効。
          <br />
        </List.Item>
        <List.Item>
          法人の場合（法人口座へ入金）
          <br />
          発行より3ヶ月以内のもののみ有効
          <Red>
            <List.List>
              <List.Item>登記事項証明書（原本）</List.Item>
              <List.Item>印鑑登録証明書（原本）</List.Item>
            </List.List>
          </Red>
          ※上記の内いずれかと、ご担当者様の身分証明書と名刺を1枚お願い致します。
          <br />
          ※法人のお客様は会員登録いただいても取引毎に上記証明書が必要になります。
        </List.Item>
        <List.Item>
          未成年のお客様について
          <br />
          当店では満20歳未満のお客様の買取はお断りしております。
        </List.Item>
        <List.Item>
          <Red>
            ※買取金額の合計が50万円以上の場合には書留の上限金額が50万円のため、お支払方法が『振込での送金限定』となりますので、あらかじめご了承下さい。
          </Red>
        </List.Item>
      </List>
    </Segment>
  </InfoView>
);

export default Mail;
