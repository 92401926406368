import { CREATE_MESSAGE, GET_ERRORS } from "./types";

// CREATE MESSAGE
const createMessage = (msg) => {
  return {
    type: CREATE_MESSAGE,
    payload: msg,
  };
};

// RETURN ERRORS
const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status },
  };
};

export const dispatchSuccess = (dispatch, msg, success, payload = null) => {
  if (msg) dispatch(createMessage(msg));
  if (success && payload) dispatch({ type: success, payload });
  else if (success) dispatch({ type: success });
};

export const dispatchError = (dispatch, err, error) => {
  if (err) dispatch(returnErrors(err.response.data, err.response.status));
  if (error) dispatch({ type: error });
};
