export const getFlatArr = (tree, level) => {
  if (level === 3) {
    return tree.map((c) => c.brands.map((b) => b.products).flat()).flat();
  } else if (level === 2) {
    return tree.map((b) => b.products).flat();
  }
};

const compare = (s, kwd) => s && s.includes(kwd);

export const getFiltered = (arr, keyword) => {
  return arr.filter(
    (p) =>
      compare(p.name, keyword) ||
      compare(p.category_name, keyword) ||
      compare(p.brand_name, keyword) ||
      p.code === keyword
  );
};

export const kComma = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
