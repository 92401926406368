import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const Phone = ({ tel = "03-5956-8558" }) => (
  <a href={`tel:${tel}`} className="text-red">
    <Icon name="phone square" fitted /> {tel}
  </a>
);

Phone.propTypes = {
  tel: PropTypes.string,
};

export default Phone;
