import React from "react";
import { Link } from "react-router-dom";
import * as urls from "../common/urls";
import { Divider } from "semantic-ui-react";

const footerLinks = [
  { title: "会社案内", url: urls.ABOUT },
  { title: "店頭買取", url: urls.WALKIN },
  { title: "郵送買取", url: urls.MAIL },
  { title: "法人買取", url: urls.ORGANIZATION },
  { title: "注意事項", url: urls.NOTICE },
  { title: "利用規約", url: urls.TERMS },
  { title: "お問い合わせ", url: urls.CONTACT },
];

const year = new Date().getFullYear();

const Footer = () => (
  <footer>
    <Divider />
    <small>
      | 運営会社：インターコネクト株式会社 | お問い合わせ：info@lala-mobile.jp |
      中古品許可番号：第305501309071 |
    </small>
    <br />
    <small>
      {footerLinks.map((link, idx) => (
        <React.Fragment key={idx}>
          | <Link to={link.url}>{link.title}</Link>{" "}
        </React.Fragment>
      ))}
      | <a href={urls.DASHBOARD}>LALA-Admin</a> |{" "}
      <a href={urls.INTWMS}>INT-WMS</a> |{" "}
    </small>
    <br />
    <small>
      Copyright &copy; {year} Inter-con Co., Ltd. All Rights Reserved.
    </small>
  </footer>
);

export default Footer;
