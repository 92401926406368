import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import { AGREEMENT } from "../common/constants";

const props = {
  as: "a",
  target: "_blank",
  rel: "noopener noreferrer",
  inverted: true,
  fluid: true,
  color: "red",
  style: {
    marginBottom: "5px",
  },
  icon: true,
  labelPosition: "left",
};

const DownloadButtons = ({ kaitori }) => (
  <>
    <Button {...props} href={kaitori}>
      <Icon name="download" />
      買取申込書
    </Button>
    <Button {...props} href={AGREEMENT}>
      <Icon name="download" />
      保護者同意書
    </Button>
  </>
);

DownloadButtons.propTypes = {
  kaitori: PropTypes.string.isRequired,
};

export default DownloadButtons;
