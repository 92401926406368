import {
  MOBILES_LISTING,
  MOBILES_LISTED,
  ELECTRONICS_LISTING,
  ELECTRONICS_LISTED,
  COSMETICS_LISTING,
  COSMETICS_LISTED,
  MEDICINES_LISTING,
  MEDICINES_LISTED,
  ALCOHOLS_LISTING,
  ALCOHOLS_LISTED,
} from "../common/types";

const initialState = {
  mobilesListing: false,
  mobiles: [],
  electronicsListing: false,
  electronics: [],
  cosmeticsListing: false,
  cosmetics: [],
  medicinesListing: false,
  medicines: [],
  alcoholsListing: false,
  alcohols: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MOBILES_LISTING:
      return {
        ...state,
        mobilesListing: true,
        mobiles: [],
      };
    case MOBILES_LISTED:
      return {
        ...state,
        mobilesListing: false,
        mobiles: action.payload,
      };
    case ELECTRONICS_LISTING:
      return {
        ...state,
        electronicsListing: true,
        electronics: [],
      };
    case ELECTRONICS_LISTED:
      return {
        ...state,
        electronicsListing: false,
        electronics: action.payload,
      };
    case COSMETICS_LISTING:
      return {
        ...state,
        cosmeticsListing: true,
        cosmetics: [],
      };
    case COSMETICS_LISTED:
      return {
        ...state,
        cosmeticsListing: false,
        cosmetics: action.payload,
      };
    case MEDICINES_LISTING:
      return {
        ...state,
        medicinesListing: true,
        medicines: [],
      };
    case MEDICINES_LISTED:
      return {
        ...state,
        medicinesListing: false,
        medicines: action.payload,
      };
    case ALCOHOLS_LISTING:
      return {
        ...state,
        alcoholsListing: true,
        alcohols: [],
      };
    case ALCOHOLS_LISTED:
      return {
        ...state,
        alcoholsListing: false,
        alcohols: action.payload,
      };
    default:
      return state;
  }
}
