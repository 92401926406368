import { combineReducers } from "redux";
import accounts from "./accounts";
import news from "./news";
import products from "./products";
import errors from "./errors";
import messages from "./messages";

export default combineReducers({
  accounts,
  news,
  products,
  errors,
  messages,
});
