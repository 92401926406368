import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./redux/store";
import * as urls from "./common/urls";
import { loadUser } from "./redux/actions/accounts";

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { Container } from "semantic-ui-react";
import Alerts from "./components/Alerts";
import Navbar from "./components/Navbar";

import Mobile from "./pages/Mobile";
import Electronics from "./pages/Electronics";
import Cosmetics from "./pages/Cosmetics";
import Warehouse from "./pages/Warehouse";
import NoMatch from "./pages/NoMatch";
import About from "./pages/About";
import Walkin from "./pages/Walkin";
import Mail from "./pages/Mail";
import Organization from "./pages/Organization";
import Notice from "./pages/Notice";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Medicine from "./pages/Medicine";
import Alcohol from "./pages/Alcohol";
import Terms from "./pages/Terms";
import Login from "./pages/Login";

// Alert Options
const alertOptions = {
  timeout: 3000,
  position: "bottom center",
};

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Alerts />
        <Container style={{ marginBottom: "20px" }}>
          <Router>
            <Navbar />
            <Switch>
              <Route exact path={urls.HOME} component={Mobile} />
              <Route exact path={urls.ELECTRONICS} component={Electronics} />
              <Route exact path={urls.COSMETICS} component={Cosmetics} />
              <Route exact path={urls.MEDICINE} component={Medicine} />
              <Route exact path={urls.ALCOHOL} component={Alcohol} />
              <Route exact path={urls.WAREHOUSE} component={Warehouse} />
              <Route exact path={urls.ABOUT} component={About} />
              <Route exact path={urls.WALKIN} component={Walkin} />
              <Route exact path={urls.MAIL} component={Mail} />
              <Route exact path={urls.ORGANIZATION} component={Organization} />
              <Route exact path={urls.NOTICE} component={Notice} />
              <Route exact path={urls.TERMS} component={Terms} />
              <Route exact path={urls.CONTACT} component={Contact} />
              <Route exact path={urls.LOGIN} component={Login} />
              <Route component={NoMatch} />
            </Switch>
            <Footer />
          </Router>
        </Container>
      </AlertProvider>
    </Provider>
  );
};

export default App;
