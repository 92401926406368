import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
// import BasicView from "../components/BasicView";
// import { KAITORI_C } from "../common/constants";

const Warehouse = () => {
  return (
    <Segment textAlign="center" placeholder>
      <Header icon>
        <Icon name="warehouse" />
        中古アウトレット
      </Header>
      <p>準備中</p>
    </Segment>
  );
};

export default Warehouse;
