import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listAlcohols } from "../redux/actions/products";
import BasicView from "../components/BasicView";
import { KAITORI_C } from "../common/constants";
import { getFlatArr, getFiltered } from "../common/utils";
import SearchBox from "../components/SearchBox";
import SideMenu from "../components/SideMenu";
import ScrollList from "../components/ScrollList";

const Alcohol = ({ alcohols, alcoholsListing, listAlcohols }) => {
  useEffect(listAlcohols, []);
  const [activeIdx, setIdx] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const arr = getFlatArr(alcohols, 2);
  const menuItems = alcohols.map((b) => ({
    id: b.id,
    text: b.name,
    num: b.products.length,
  }));
  const recommended = arr.filter((p) => p.recommended);

  return (
    <BasicView
      pageIdx={4}
      kaitori={KAITORI_C}
      itemListing={alcoholsListing}
      queryProps={{ levels: 2, tree: alcohols }}
      nodeSearch={
        <SearchBox
          clickEvent={() => setIdx(-2)}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      }
      nodeMenu={
        <SideMenu
          activeIdx={activeIdx}
          setIdx={setIdx}
          num0={recommended.length}
          items={menuItems}
        />
      }
      nodeBoard={
        activeIdx === -2 ? (
          <ScrollList
            header="商品検索"
            body={getFiltered(arr, keyword)}
            icon="search"
            empty="ご指定の検索条件に該当する商品はみつかりませんでした"
          />
        ) : activeIdx === -1 ? (
          <ScrollList header="強化買取" body={recommended} icon="thumbs up" />
        ) : (
          <ScrollList
            header={alcohols[activeIdx].name}
            body={alcohols[activeIdx].products}
            icon="shopping cart"
          />
        )
      }
    />
  );
};

Alcohol.propTypes = {
  listAlcohols: PropTypes.func.isRequired,
  alcohols: PropTypes.array.isRequired,
  alcoholsListing: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  alcohols: state.products.alcohols,
  alcoholsListing: state.products.alcoholsListing,
});

export default connect(mapStateToProps, { listAlcohols })(Alcohol);
