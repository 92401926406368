import React from "react";
import PropTypes from "prop-types";
import { Table, Header, Message, Label, Divider } from "semantic-ui-react";
import { kComma } from "../common/utils";

const ScrollTable = ({ header, body, icon, empty, upperInfo = false }) => {
  let headers = ["機種名", "説明", "新品", "中古"];
  let widths = [6, 6, 2, 2];
  if (upperInfo) {
    // activeIdx = -2 or -1
    headers = ["カテゴリー", "ブランド", ...headers];
    widths = [2, 2, 4, 4, 2, 2];
  }

  return (
    <>
      <Divider horizontal>
        <Header as="h5" icon={icon} content={header} />
      </Divider>
      {body && body.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row>
              {headers.map((h, idx) => (
                <Table.HeaderCell width={widths[idx]} key={idx}>
                  {h}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {body.map((row) => (
              <Table.Row key={row.id}>
                {upperInfo ? (
                  <Table.Cell className="text-bold">
                    {row.category_name}
                  </Table.Cell>
                ) : null}
                {upperInfo ? (
                  <Table.Cell className="text-bold">
                    {row.brand_name}
                  </Table.Cell>
                ) : null}
                <Table.Cell className="text-bold">
                  {row.recommended ? (
                    <Label color="red" content="強化" size="mini" basic />
                  ) : null}{" "}
                  {row.name}
                </Table.Cell>
                <Table.Cell className="text-teal">{row.description}</Table.Cell>
                <Table.Cell className="text-red">
                  ¥{kComma(row.price)}
                </Table.Cell>
                <Table.Cell>¥{kComma(row.price2)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : empty ? (
        <Message size="small" content={empty} />
      ) : null}
    </>
  );
};

ScrollTable.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  empty: PropTypes.string,
  upperInfo: PropTypes.bool,
};

export default ScrollTable;
