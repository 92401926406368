import React from "react";
import PropTypes from "prop-types";
import { colors, sizes } from "../common/constants";
import { Message } from "semantic-ui-react";

const colorSet = colors.map(({ code }) => code);
const sizeSet = sizes.map(({ code }) => code);
const getType = (style) => {
  if (colorSet.includes(style)) return "COLOR";
  if (sizeSet.includes(style)) return "SIZE";
  if (style === "WHITE") return "COLOR";
  if (style === "BOLD" || style === "ITALIC") return "WEIGHT";
  return "DECOR";
};

const getCSS = (key) => {
  const colorMap = colors.reduce(
    (obj, item) => Object.assign(obj, { [item.code]: item.hex }),
    {}
  );
  const sizeMap = sizes.reduce(
    (obj, item) => Object.assign(obj, { [item.code]: item.size }),
    {}
  );
  const searchDict = {
    ...colorMap,
    ...sizeMap,
    WHITE: "#fff",
    STRIKETHROUGH: "line-through",
    BOLD: "bold",
    ITALIC: "italic",
    UNDERLINE: "underline",
  };
  return searchDict[key];
};

const normalizeStyles = (line, styles) => {
  let styleArr = Array(line.length).fill([]);
  for (let i = 0; i < styles.length; i++) {
    const { length, offset, style } = styles[i];
    for (let j = 0; j < length; j++) {
      styleArr[j + offset] = [...styleArr[j + offset], [style, getType(style)]];
    }
  }
  // Reduce color and size
  styleArr = styleArr.map((s) => {
    let res = {};
    // Get general
    const decor = s.filter((x) => x[1] === "DECOR");
    const weight = s.filter((x) => x[1] === "WEIGHT");
    if (decor.length) {
      const dCSS = decor.map((x) => getCSS(x[0]));
      res = { ...res, textDecoration: [...new Set(dCSS)].join(" ") };
    }
    if (weight.length) {
      const wCSS = weight.map((x) => getCSS(x[0]));
      res = { ...res, fontWeight: [...new Set(wCSS)].join(" ") };
    }
    // Get color
    const color = s.filter((x) => x[1] === "COLOR").pop();
    if (color) res = { ...res, color: getCSS(color[0]) };
    // Get size
    const size = s.filter((x) => x[1] === "SIZE").pop();
    if (size) res = { ...res, fontSize: getCSS(size[0]) };
    return res;
  });
  return styleArr;
};

const isEmpty = (blocks) => {
  if (!blocks || !blocks.length) return true;
  const lines = blocks.map((line) => line.text);
  return lines.every((t) => !t.trim());
};

const MyMessage = ({ newsObject }) => {
  if (!newsObject) return null;
  const { content: blocks, background: theme } = newsObject;
  if (isEmpty(blocks)) {
    return null;
  }
  return (
    <Message color={theme}>
      {blocks.map(({ key, text, inlineStyleRanges }) => {
        const lineStyle = normalizeStyles(text, inlineStyleRanges);
        return (
          <p key={key}>
            {text.split("").map((letter, idx) => {
              return (
                <span key={idx} style={lineStyle[idx]}>
                  {letter}
                </span>
              );
            })}
          </p>
        );
      })}
    </Message>
  );
};

MyMessage.propTypes = {
  newsObject: PropTypes.object,
};

export default MyMessage;
