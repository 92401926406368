import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listMobiles } from "../redux/actions/products";
import BasicView from "../components/BasicView";
import { KAITORI_M } from "../common/constants";
import { getFlatArr, getFiltered } from "../common/utils";
import SearchBox from "../components/SearchBox";
import SideMenu from "../components/SideMenu";
import ScrollTable from "../components/ScrollTable";

const Mobile = ({ mobiles, mobilesListing, listMobiles }) => {
  useEffect(listMobiles, []);
  const [activeIdx, setIdx] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const arr = getFlatArr(mobiles, 3);
  const menuItems = mobiles.map((c) => ({
    id: c.id,
    text: c.name,
    num: getFlatArr(c.brands, 2).length,
  }));
  const recommended = arr.filter((p) => p.recommended);
  // Set Index to 0
  useEffect(() => {
    if (mobiles.length) setIdx(0);
    else setIdx(-1);
  }, [mobiles]);

  return (
    <BasicView
      pageIdx={0}
      kaitori={KAITORI_M}
      itemListing={mobilesListing}
      queryProps={{ levels: 3, tree: mobiles }}
      nodeSearch={
        <SearchBox
          clickEvent={() => setIdx(-2)}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      }
      nodeMenu={
        <SideMenu
          activeIdx={activeIdx}
          setIdx={setIdx}
          num0={recommended.length}
          items={menuItems}
        />
      }
      nodeBoard={
        activeIdx === -2 ? (
          <ScrollTable
            header="商品検索"
            body={getFiltered(arr, keyword)}
            icon="search"
            empty="ご指定の検索条件に該当する商品はみつかりませんでした"
            upperInfo
          />
        ) : activeIdx === -1 || !mobiles[activeIdx] ? (
          <ScrollTable
            header="強化買取"
            body={recommended}
            icon="thumbs up"
            upperInfo
          />
        ) : (
          mobiles[activeIdx].brands.map((b) => (
            <ScrollTable
              key={b.id}
              header={b.name}
              body={b.products}
              icon="mobile alternate"
            />
          ))
        )
      }
    />
  );
};

Mobile.propTypes = {
  listMobiles: PropTypes.func.isRequired,
  mobiles: PropTypes.array.isRequired,
  mobilesListing: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  mobiles: state.products.mobiles,
  mobilesListing: state.products.mobilesListing,
});

export default connect(mapStateToProps, { listMobiles })(Mobile);
