import React from "react";
import PropTypes from "prop-types";
import { Card, Header, Message, Image, Divider } from "semantic-ui-react";
import { kComma } from "../common/utils";
import { NO_IMG } from "../common/constants";

const ScrollList = ({ header, body, icon, empty }) => (
  <>
    <Divider horizontal>
      <Header as="h5" icon={icon} content={header} />
    </Divider>
    {body && body.length ? (
      <Card.Group itemsPerRow={4} stackable>
        {body.map((row) => (
          <Card key={row.id} fluid>
            <Image
              src={row.photo ? row.photo : NO_IMG}
              wrapped
              ui={false}
              className="thumb"
            />
            <Card.Content>
              <Card.Description>
                <p className="text-sm">{row.name}</p>
                <p className="text-sm text-red">¥{kComma(row.price)}</p>
                <p className="text-sm text-teal">{row.description}</p>
              </Card.Description>
            </Card.Content>
            {row.code ? (
              <Card.Content extra>
                <p className="text-sm">JAN：{row.code}</p>
              </Card.Content>
            ) : null}
          </Card>
        ))}
      </Card.Group>
    ) : empty ? (
      <Message size="small" content={empty} />
    ) : null}
  </>
);

ScrollList.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  empty: PropTypes.string,
};

export default ScrollList;
