import React from "react";
import { Grid, Segment, Table } from "semantic-ui-react";
import Phone from "../components/Phone";
import InfoView from "../components/InfoView";
import Map from "../components/Map";

const About = () => (
  <InfoView header="店舗案内">
    <Grid stackable columns="equal">
      <Grid.Column>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width="4">会社名</Table.Cell>
              <Table.Cell width="12">インターコネクト株式会社</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>電話番号</Table.Cell>
              <Table.Cell>
                <Phone />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>メール</Table.Cell>
              <Table.Cell>info@lala-mobile.jp</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>事業内容</Table.Cell>
              <Table.Cell>
                通信機器、医療機器、日用品雑貨及びこれらの古物の買取、販売及び輸出入
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>店舗住所</Table.Cell>
              <Table.Cell>東京都豊島区池袋1-1-8 SKYビル1階</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>営業時間</Table.Cell>
              <Table.Cell>
                10:00～19:00
                <br />
                定休日：木曜日
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>古物商許可</Table.Cell>
              <Table.Cell>第305501309071号</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>アクセス</Table.Cell>
              <Table.Cell>
                JR池袋北口から徒歩2分、平和通り入ってすぐ左側に1階
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column>
        <Segment style={{ height: "100%" }}>
          <Map />
        </Segment>
      </Grid.Column>
    </Grid>
  </InfoView>
);

export default About;
