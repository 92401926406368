import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendEmail } from "../redux/actions/home";
import InfoView from "../components/InfoView";
import { Message, Segment, Form, Button } from "semantic-ui-react";
import Phone from "../components/Phone";
import { useForm, useError } from "../common/hooks";

const Contact = ({ sendEmail }) => {
  const [values, handleChange, , clearAll] = useForm({
    name: "",
    email: "",
    message: "",
  });
  const [errors, checkErrors] = useError({
    name: false,
    email: false,
    message: false,
  });

  return (
    <InfoView header="無料査定・お問い合わせ">
      <Message
        negative
        list={[
          "メールでの買取金額に関するお問い合わせはお受けしておりません。",
          "お手数ですが電話にてお問い合わせをお願い致します。",
        ]}
      />
      <Message info>
        <Message.List>
          <Message.Item>
            電話によるお問合わせ：
            <Phone />
          </Message.Item>
          <Message.Item>受付時間：10:00～19:00（定休日：木曜日）</Message.Item>
        </Message.List>
      </Message>

      <Segment>
        <Form size="small">
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="お名前"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              required
            />
            <Form.Input
              name="email"
              label="メールアドレス"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              required
            />
          </Form.Group>
          <Form.TextArea
            name="message"
            label="お問い合わせ内容（商品型番・JANコード・状態・台数など）"
            rows="10"
            value={values.message}
            onChange={handleChange}
            error={errors.message}
            required
          />
          <Button
            content="送信する"
            icon="send"
            labelPosition="left"
            color="red"
            size="tiny"
            type="button"
            compact
            onClick={() => {
              if (checkErrors(values)) return;
              sendEmail(values);
              clearAll();
            }}
          />
        </Form>
      </Segment>
    </InfoView>
  );
};

Contact.propTypes = {
  sendEmail: PropTypes.func.isRequired,
};

export default connect(null, {
  sendEmail,
})(Contact);
