import React from "react";
import InfoView from "../components/InfoView";
import { Segment, Header } from "semantic-ui-react";

const Terms = () => (
  <InfoView header="利用規約">
    <Segment>
      <Header as="h3" icon="lock" content="個人情報保護方針" />
      <p>
        ララモバイルでは、ホームページ（以下、「当サイト」という）の運営に際し、お客様の個人情報を尊重し、個人情報に対して十分な配慮を行うと共に大切に保護し、適正な管理を行う為、以下のように個人情報保護方針を定めます。
      </p>
      <Header as="h4" content="法令などの遵守" />
      <p>
        弊社は、お客様等の個人情報の取得、利用その他一切の取り扱いについて、個人情報の保護に関する法律、通信の秘密に係る電気通信事業法の規定その他の関連法令、電気通信事業における個人情報保護に関するガイドライン及びこのプライバシーポリシーを遵守します。
      </p>
      <Header as="h4" content="個人情報利用目的" />
      <p>
        お客様の個人情報は、原則として、当社のサービスに関する情報をご提供する目的や発送案内、ご注文に関するお知らせ、当社に対するご意見、ご要望に関する今後の改善、及び、問い合せに関するご回答等のために利用致します。
        <br />
        それ以外の目的で利用する場合は個人情報をご提供いただく際に予め目的を明示しておりますのでご確認下さい。
      </p>
      <Header as="h4" content="第三者への情報提供" />
      <p>
        お客様の個人情報は、以下の場合を除き第三者に開示、提供、譲渡、することは致しません。
        <br />
        １、当社の業務委託先(運送会社等)、業務遂行上必要な場合
        <br />
        ２、法的拘束力がある第三者機関からの開示要求がある場合
        <br />
        ３、お客様本人の同意があった場合
      </p>
      <Header as="h4" content="個人情報の開示・訂正・削除について" />
      <p>
        お客様ご自身の個人情報の開示・訂正・追加・削除・消去については、そのお客様からお申し出があった場合、ご本人確認の手続きをとらせていただいた上で、無料にて行わさせていただきます。
      </p>
      <Header
        as="h4"
        content="個人情報に関するご連絡先/お問い合わせ先/苦情受付"
      />
      <p>
        お客様等が個人情報の利用目的の通知、個人情報の開示、訂正、追加あるいは削除をご希望される場合、または個人情報の利用あるいは第三者への提供の停止を希望される場合は、下記にお問合せください。
      </p>
      <Header as="h4" content="お問合せ窓口" />
      <p>
        電話番号：03-5956-8558
        <br />
        メールアドレス：info@lala-mobile.jp
      </p>
      <Header as="h4" content="セキュリティについて" />
      <p>
        SecureSocketsLayer（セキュア・ソケット・レイヤー）の略で、暗号化された秘匿性の高い通信を提供します。
        <br />
        お客様のブラウザから送信された情報（ユーザーID/パスワード/お客様の個人情報など）や、該当ページから返信された情報を暗号化する技術です。
        <br />
        当サイトは、この技術により、お客様の入力した個人情報が第三者に漏れないようにしております。
        <br />
      </p>
      <p style={{ fontWeight: "bold", marginTop: "30px" }}>
        2021年1月1日
        <br />
        インターコネクト株式会社
        <br />
        店舗運営責任者　加藤　洲
      </p>
    </Segment>
  </InfoView>
);

export default Terms;
