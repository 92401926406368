import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { useError, useForm } from "../common/hooks";
import { prefectures } from "../common/constants";
import FileInput from "../components/FileInput";
import { TERMS } from "../common/urls";
import { signUp } from "../redux/actions/accounts";

const hf = {
  username: "",
  password: "",
  re_password: "",
  email: "",
  first_name: "",
  last_name: "",
  furikana_first: "",
  furikana_last: "",
  gender: "",
  job: "",
  tel: "",
  zipcode: "",
  prefecture: "",
  address: "",
  payment_method: "",
  id_front: "",
  id_back: "",
  note: "",
};

const rf = {
  username: false,
  password: false,
  re_password: false,
  email: false,
  first_name: false,
  last_name: false,
  furikana_first: false,
  furikana_last: false,
  gender: false,
  job: false,
  tel: false,
  zipcode: false,
  prefecture: false,
  address: false,
  payment_method: false,
  id_front: false,
  id_back: false,
};

const Signup = ({ signUp }) => {
  const [values, handleChange, handleClick] = useForm(hf);
  const [errors, checkErrors] = useError(rf);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (checkErrors(values)) return;
        signUp(values);
      }}
    >
      <Header as="h3" textAlign="center">
        新規登録
      </Header>
      <Form.Input
        label="ユーザー名"
        name="username"
        value={values.username}
        onChange={handleChange}
        error={errors.username}
        required
      />
      <Form.Input
        label="パスワード"
        type="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        error={errors.password}
        required
      />
      <Form.Input
        label="パスワード（確認）"
        type="password"
        name="re_password"
        value={values.re_password}
        onChange={handleChange}
        error={errors.re_password}
        required
      />
      <Form.Input
        label="メールアドレス"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
        required
      />
      <Form.Input
        label="姓"
        name="last_name"
        value={values.last_name}
        onChange={handleChange}
        error={errors.last_name}
        required
      />
      <Form.Input
        label="フリカナ（姓）"
        name="furikana_last"
        value={values.furikana_last}
        onChange={handleChange}
        error={errors.furikana_last}
        required
      />
      <Form.Input
        label="名"
        name="first_name"
        value={values.first_name}
        onChange={handleChange}
        error={errors.first_name}
        required
      />
      <Form.Input
        label="フリカナ（名）"
        name="furikana_first"
        value={values.furikana_first}
        onChange={handleChange}
        error={errors.furikana_first}
        required
      />
      <FileInput
        name="id_front"
        label="身分証表面"
        placeholder="参照..."
        value={values.id_front}
        onChange={handleClick}
        error={errors.id_front}
      />
      <FileInput
        name="id_back"
        label="身分証裏面"
        placeholder="参照..."
        value={values.id_back}
        onChange={handleClick}
        error={errors.id_back}
      />
      <Form.Select
        label="性別"
        name="gender"
        value={values.gender}
        onChange={(e, { value }) => handleClick("gender", value)}
        error={errors.gender}
        options={[
          { key: "m", text: "男", value: "male" },
          { key: "f", text: "女", value: "female" },
          { key: "o", text: "その他", value: "other" },
        ]}
        required
      />
      <Form.Input
        label="職業"
        name="job"
        value={values.job}
        onChange={handleChange}
        error={errors.job}
        required
      />
      <Form.Input
        label="電話番号"
        name="tel"
        value={values.tel}
        onChange={handleChange}
        error={errors.tel}
        required
      />
      <Form.Input
        label="郵便番号（〒）"
        name="zipcode"
        value={values.zipcode}
        onChange={handleChange}
        error={errors.zipcode}
        required
      />
      <Form.Select
        label="都道府県"
        name="prefecture"
        value={values.prefecture}
        onChange={(e, { value }) => handleClick("prefecture", value)}
        error={errors.prefecture}
        options={prefectures}
        required
      />
      <Form.Input
        label="住所"
        name="address"
        value={values.address}
        onChange={handleChange}
        error={errors.address}
        required
      />
      <Form.Select
        label="送金方法"
        name="payment_method"
        value={values.payment_method}
        onChange={(e, { value }) => handleClick("payment_method", value)}
        error={errors.payment_method}
        options={[
          { key: "cash", text: "現金書留", value: "cash" },
          { key: "bank", text: "銀行振込", value: "bank" },
        ]}
        required
      />
      <Form.TextArea
        label="備考"
        name="note"
        value={values.note}
        onChange={handleChange}
      />
      <Button color="red" fluid type="submit">
        我已同意利用规约并确认注册
      </Button>
      <p>
        <Link to={TERMS} target="_blank">
          利用規約（個人情報）
        </Link>
      </p>
    </Form>
  );
};

Signup.propTypes = {
  signUp: PropTypes.func.isRequired,
};

export default connect(null, { signUp })(Signup);
