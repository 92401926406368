import { list } from "../common/requests";
import {
  MOBILES_LISTING,
  MOBILES_LISTED,
  MOBILES_FAIL,
  ELECTRONICS_LISTING,
  ELECTRONICS_LISTED,
  ELECTRONICS_FAIL,
  COSMETICS_LISTING,
  COSMETICS_LISTED,
  COSMETICS_FAIL,
  MEDICINES_LISTING,
  MEDICINES_LISTED,
  MEDICINES_FAIL,
  ALCOHOLS_LISTING,
  ALCOHOLS_LISTED,
  ALCOHOLS_FAIL,
} from "../common/types";

const urls = {
  m: "/api/home/mobile-tree",
  e: "/api/home/electronics-tree",
  c: "/api/home/cosmetics-tree",
  d: "/api/home/medicine-tree",
  a: "/api/home/alcohol-tree",
};

export const listMobiles = () =>
  list(urls.m, MOBILES_LISTED, MOBILES_FAIL, MOBILES_LISTING);

export const listElectronics = () =>
  list(urls.e, ELECTRONICS_LISTED, ELECTRONICS_FAIL, ELECTRONICS_LISTING);

export const listCosmetics = () =>
  list(urls.c, COSMETICS_LISTED, COSMETICS_FAIL, COSMETICS_LISTING);

export const listMedicines = () =>
  list(urls.d, MEDICINES_LISTED, MEDICINES_FAIL, MEDICINES_LISTING);

export const listAlcohols = () =>
  list(urls.a, ALCOHOLS_LISTED, ALCOHOLS_FAIL, ALCOHOLS_LISTING);
