import React from "react";
import { Image, Menu, Header, Label, Segment, Grid } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import Phone from "./Phone";
import * as urls from "../common/urls";
// import NavbarUser from "./NavbarUser";

const mainSections = [
  { title: "携帯買取", url: urls.HOME, icon: "mobile" },
  { title: "家電買取", url: urls.ELECTRONICS, icon: "gamepad" },
  { title: "日用品買取", url: urls.COSMETICS, icon: "shopping basket" },
  { title: "医薬品買取", url: urls.MEDICINE, icon: "medkit" },
  { title: "お酒買取", url: urls.ALCOHOL, icon: "glass martini" },
  // { title: "中古アウトレット", url: urls.WAREHOUSE },
];

const infoSections = [
  { title: "ホーム", url: urls.HOME },
  { title: "店頭買取", url: urls.WALKIN },
  { title: "郵送買取", url: urls.MAIL },
  { title: "法人買取", url: urls.ORGANIZATION },
  { title: "店舗案内", url: urls.ABOUT },
  { title: "注意事項", url: urls.NOTICE },
  { title: "利用規約", url: urls.TERMS },
  { title: "お問い合わせ", url: urls.CONTACT },
];

const classes = {
  grid: {
    marginTop: "25px",
    marginBottom: "10px",
  },
  header: {
    fontSize: "3rem",
  },
  sub: {
    marginTop: "10px",
    fontSize: "1rem",
  },
  top: {
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  menu: {
    fontWeight: "bold",
  },
};

const Navbar = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const pathCheck = urls.checkLoation(pathName);

  const renderLink = (section) => (
    <Menu.Item
      as={Link}
      to={section.url}
      key={section.url}
      name={section.title}
      active={pathName === section.url}
      icon={section.icon ? section.icon : null}
      style={classes.menu}
    />
  );

  return (
    <>
      {/*
      <Menu text style={classes.top} floated="right" color="red">
        <NavbarUser />
      </Menu>
    */}
      <Segment
        textAlign="center"
        size="small"
        content="iPhoneやiPad 、スマートフォン携帯電話・電気製品・日用品・医薬品の高値買取ならララモバイルにお任せ下さい。"
      />
      <Grid stackable style={classes.grid} stretched>
        <Grid.Column width={6}>
          <Header as="h1" style={classes.header}>
            <Image src={urls.LOGO} size="medium" />
            <Header.Content>
              {pathCheck === 1 || pathCheck === 2 ? "LALA SHOP" : "LALA MOBILE"}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Label color="red" size="large" style={{ width: "100%" }}>
            <span style={{ display: "block", fontSize: "2rem" }}>
              全国買取NO.1
              <span style={{ color: "lightgray", fontSize: "1rem" }}>
                挑戦中
              </span>
            </span>
            <span style={{ display: "block", marginTop: "15px" }}>
              池袋北口徒步2分
            </span>
          </Label>
        </Grid.Column>
        <Grid.Column width={5}>
          <Label color="red" basic size="large" style={{ width: "100%" }}>
            <span style={{ display: "block", fontSize: "1.6rem" }}>
              <Phone />
            </span>
            <span style={{ display: "block", marginTop: "5px" }}>
              営業時間：10:00~19:00（定休日：木曜日）
            </span>
            <span style={{ display: "block", marginTop: "5px" }}>
              東京都豊島区池袋1-1-8 SKYビル1階
            </span>
          </Label>
        </Grid.Column>
      </Grid>

      <Menu
        color="red"
        inverted
        compact
        widths={infoSections.length}
        style={classes.menu}
      >
        {infoSections.map(renderLink)}
      </Menu>
      <Menu
        secondary
        size="huge"
        icon="labeled"
        color="red"
        widths={mainSections.length}
        style={classes.menu}
      >
        {mainSections.map(renderLink)}
      </Menu>
    </>
  );
};

export default Navbar;
