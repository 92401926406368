import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Form,
  Icon,
  Label,
  Modal,
  Table,
  Image,
} from "semantic-ui-react";
import { useForm } from "../common/hooks";

const hrls = {
  hf2: { brand: "0", product: "0" },
  hf3: { category: "0", brand: "0", product: "0" },
  lk2: ["brand", "product"],
  lk3: ["category", "brand", "product"],
};

const translation = {
  category: "カテゴリ",
  brand: "ブランド",
  product: "商品名",
};

const QueryBox = ({ levels, tree }) => {
  const [values, handleChange] = useForm(hrls[`hf${levels}`]);
  const [myModal, setMyModal] = useState({
    open: false,
    info: null,
  });
  const levelKeys = hrls[`lk${levels}`];

  const getMyLeaves = (currLevel) => {
    if (currLevel === 0) return tree ? tree : [];
    const currKey = levelKeys[currLevel];
    const parentLevel = currLevel - 1;
    const parentKey = levelKeys[parentLevel];
    const parentId = values[parentKey];
    const myRoot = getMyLeaves(parentLevel).find(
      (p) => p.id === parseInt(parentId)
    );
    if (!myRoot) return [];
    return myRoot[`${currKey}s`];
  };

  const isDisabled = (currLevel) => {
    if (!currLevel) return false;
    const v0 = values[levelKeys[0]] === "0";
    const v1 = values[levelKeys[1]] === "0";
    return currLevel === 1 ? v0 : v0 || v1;
  };

  const mapToOptions = (o) => (
    <option value={`${o.id}`} key={o.id}>
      {o.name}
    </option>
  );

  return (
    <>
      <Card fluid color="red">
        <Card.Content>
          <Card.Header>
            <Icon name="search" /> 無料スピード査定
          </Card.Header>
          <Form size="small" style={{ marginTop: "15px" }}>
            {levelKeys.map((lv, idx) => (
              <Form.Field
                control="select"
                name={lv}
                value={values[lv]}
                key={lv}
                onChange={handleChange}
                disabled={isDisabled(idx)}
              >
                <option value="0">{translation[lv]}をお選びください</option>
                {getMyLeaves(idx).map(mapToOptions)}
              </Form.Field>
            ))}
            <Button
              fluid
              color="red"
              content="価格をチェック"
              compact
              onClick={() => {
                if (levels === 3) {
                  if (!values.category || values.category === "0") return;
                }
                if (!values.brand || values.brand === "0") return;
                if (!values.product || values.product === "0") return;
                const p = getMyLeaves(levels - 1).find(
                  (p) => p.id === parseInt(values.product)
                );
                if (p) setMyModal({ open: true, info: p });
              }}
            />
          </Form>
        </Card.Content>
      </Card>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={myModal.open}
      >
        <Modal.Header>商品情報</Modal.Header>
        {myModal.info ? (
          <Modal.Content scrolling>
            <Modal.Description>
              {myModal.info.recommended ? (
                <Label color="red" basic size="large" content="強化買取" />
              ) : null}
              <div style={{ margin: "20px 0" }}>
                {myModal.info.photo ? (
                  <Image
                    src={myModal.info.photo}
                    wrapped
                    rounded
                    size="medium"
                  />
                ) : null}
              </div>
              <Table definition>
                <Table.Body>
                  {myModal.info.category_name !== undefined ? (
                    <Table.Row>
                      <Table.Cell>カテゴリ</Table.Cell>
                      <Table.Cell>{myModal.info.category_name}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  <Table.Row>
                    <Table.Cell>ブランド</Table.Cell>
                    <Table.Cell>{myModal.info.brand_name}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>商品名</Table.Cell>
                    <Table.Cell width={12}>{myModal.info.name}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>JANコード</Table.Cell>
                    <Table.Cell>{myModal.info.code}</Table.Cell>
                  </Table.Row>
                  {myModal.info.product_id !== undefined ? (
                    <Table.Row>
                      <Table.Cell>商品コード</Table.Cell>
                      <Table.Cell>{myModal.info.product_id}</Table.Cell>
                    </Table.Row>
                  ) : null}
                  <Table.Row>
                    <Table.Cell>説明</Table.Cell>
                    <Table.Cell>{myModal.info.description}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>新品</Table.Cell>
                    <Table.Cell>{myModal.info.price}</Table.Cell>
                  </Table.Row>
                  {myModal.info.price2 !== undefined ? (
                    <Table.Row>
                      <Table.Cell>中古</Table.Cell>
                      <Table.Cell>{myModal.info.price2}</Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
        ) : null}
        <Modal.Actions>
          <Button onClick={() => setMyModal({ open: false, info: null })}>
            閉じ
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

QueryBox.propTypes = {
  levels: PropTypes.number.isRequired,
  tree: PropTypes.array,
};

export default QueryBox;
