import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

const DateTag = ({ date }) =>
  date ? (
    date.date ? (
      <Label basic color="red" content={`${date.date}更新`} />
    ) : null
  ) : null;

DateTag.propTypes = {
  date: PropTypes.object,
};

export default DateTag;
