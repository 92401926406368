import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

const Highlight = ({ children }) => (
  <Label size="big" color="black" basic>
    {children}
  </Label>
);

Highlight.propTypes = {
  children: PropTypes.node,
};

export default Highlight;
