import React from "react";
import InfoView from "../components/InfoView";
import { Segment, List, Header } from "semantic-ui-react";
import BuyInMenu from "../components/BuyInMenu";
import Phone from "../components/Phone";
import Map from "../components/Map";
import Red from "../components/Red";
import StepHeader from "../components/StepHeader";

const idItems = [
  "免許証",
  "保険証 ※（保険証をご利用頂く場合、「記号」「番号」「被保険者番号(保険者番号)」をマスキングテープなどで隠した状態のもののみ有効となります。アップロード頂く前に必ずご対応をお願い致します。）",
  "パスポート",
  "住民基本台帳カード",
  "外国人登録証",
  "住民票写しの原本(コピーはNG・発行より3ヶ月以内)",
];

const banItems = [
  "不正な商品(非ライセンス商品・模倣品・海賊版)またはその疑いがあるもの。",
  "盗品、もしくは当店にて盗品に準ずるものと判断した商品。",
  "海外版 、輸入版等、弊社で買取していない海外販売品。",
  "サンプル版やデモ版として貸与されている商品。",
  "ネットワーク利用期限のかかった携帯電話。",
  "パスワードや遠隔ロック、アクティベーションロックが解除出来ない商品。",
  "動作確認できない商品(故障している、破損している)。",
  "保護フィルムの有無、保証期間などにより減額になる場合があります。",
  "その他、当店にて購入できる状態の商品ではないと判断した商品。",
];

const Walkin = () => (
  <InfoView header="店頭買取の流れ">
    <Segment>
      <StepHeader content="STEP1 買取査定で事前に買取金額をチェック" />
      <List
        bulleted
        items={[
          "まずは サイト内買取商品の参考査定価格を記載しておりますので、そちらをご覧下さい。",
          "大口の場合でも、載ってない商品でも、お気軽にご相談ください。",
          "買取価格が異なる場合がございます。また、買取価格は日々変動いたしますので予めご了承ください。",
        ]}
      />
      <List bulleted>
        <List.Item>
          下記の方法で当店よりお買取予定額をおしらせいたします。
          <List.List>
            <List.Item>
              自動査定は、無料スピード査定　
              <BuyInMenu />
            </List.Item>
            <List.Item>
              電話査定は、
              <Phone />
            </List.Item>
          </List.List>
        </List.Item>
        <List.Item>営業時間：平日・祝日：10:00 〜 19:00 定休：木曜日</List.Item>
        <List.Item>住所：東京都豊島区池袋1-1-8 SKYビル1階</List.Item>
      </List>
      <Segment style={{ height: "100%" }}>
        <Map />
      </Segment>
      <StepHeader content="STEP2 店舗に持ち込み" />
      <List bulleted>
        <List.Item>
          ララショップの店舗に下記の物をご持参ください。
          <List.List>
            <List.Item>
              <Red>携帯電話</Red>
              （本体、付属品、保証書、取扱説明書、箱など）
            </List.Item>
            <List.Item>
              <Red>本人確認書類</Red>
              （有効な本人確認書類は以下の通りです）
              <List.List>
                {idItems.map((item, idx) => (
                  <List.Item key={idx}>{item}</List.Item>
                ))}
              </List.List>
            </List.Item>
          </List.List>
        </List.Item>
      </List>
      <StepHeader content="STEP3 査定・検品" />
      <List
        bulleted
        items={[
          "弊社スタッフがその場で商品を査定いたします。",
          "ララショップは何でも査定無料ですのでご安心下さい。",
        ]}
      />
      <StepHeader content="STEP4 その場で買取金額をを支払い" />
      <List bulleted>
        <List.Item>
          査定金額にご納得いただけた場合、その場で買取金額を現金にてお支払いいたします。
        </List.Item>
        <List.Item>
          ご納得いただけなかった場合は商品をお返しいたします。
        </List.Item>
        <List.Item>
          もちろんララショップではキャンセル等一切不要ですのでご安心下さい。
        </List.Item>
      </List>
      <Header
        as="h3"
        icon="warning circle"
        content="注意事項：以下の事項にあてはまる端末の買取りはできません。"
        block
        color="red"
      />
      <List bulleted items={banItems} />
    </Segment>
  </InfoView>
);

export default Walkin;
