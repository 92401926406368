import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

const SmallButton = ({
  content,
  onClick,
  icon = "add",
  color = "green",
  type = "button"
}) => (
  <Button
    content={content}
    icon={icon}
    labelPosition="left"
    color={color}
    size="tiny"
    type={type}
    compact
    onClick={onClick}
  />
);

SmallButton.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default SmallButton;
