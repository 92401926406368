import React from "react";
import { useHistory } from "react-router-dom";
import { Header, Button, Icon, Segment } from "semantic-ui-react";

const NoMatch = () => (
  <Segment textAlign="center" placeholder>
    <Header icon>
      <Icon name="exclamation circle" />
      404 NOT FOUND
    </Header>
    <p>ページが表示できません</p>
    <Button color="green" onClick={useHistory().goBack}>
      戻る
    </Button>
  </Segment>
);

export default NoMatch;
