import React, { Component } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  };

  componentDidUpdate = (prevProps) => {
    const { error, alert, message } = this.props;

    if (error !== prevProps.error) {
      if (error.msg instanceof Object) {
        for (const key of Object.keys(error.msg)) {
          if (error.msg[key] instanceof Array) {
            error.msg[key].map((element) => {
              return alert.error(element);
            });
          }
        }
      }
    }

    if (message !== prevProps.message) {
      for (const key of Object.keys(message)) alert.success(message[key]);
    }
  };

  render = () => {
    return <></>;
  };
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
