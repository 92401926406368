import { list } from "../common/requests";
import {
  NEWS_LISTING,
  NEWS_LISTED,
  NEWS_FAIL,
  UPDATES_LISTING,
  UPDATES_LISTED,
  UPDATES_FAIL,
} from "../common/types";

export const listNews = () =>
  list("/api/home/news", NEWS_LISTED, NEWS_FAIL, NEWS_LISTING);

export const listDates = () =>
  list("/api/home/updates", UPDATES_LISTED, UPDATES_FAIL, UPDATES_LISTING);
