import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../redux/actions/accounts";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import { useError, useForm } from "../common/hooks";
import { CUSTOMER_DASHBOARD } from "../common/urls";
import Signup from "./Signup";

const hf = {
  username: "",
  password: "",
};

const rf = {
  username: false,
  password: false,
};

const Login = ({ login, authenticated }) => {
  const [values, handleChange] = useForm(hf);
  const [errors, checkErrors] = useError(rf);

  if (authenticated) return <Redirect to={CUSTOMER_DASHBOARD} />;
  return (
    <Segment padded>
      <Grid columns={2} stackable>
        <Divider vertical>Or</Divider>
        <Grid.Column style={{ padding: "10% 5%" }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (checkErrors(values)) return;
              login(values.username, values.password);
            }}
          >
            <Header as="h3" color="red" textAlign="center">
              ログイン
            </Header>
            <Form.Input
              icon="user"
              iconPosition="left"
              placeholder="ユーザー名"
              name="username"
              value={values.username}
              onChange={handleChange}
              error={errors.username}
            />
            <Form.Input
              icon="lock"
              iconPosition="left"
              placeholder="パスワード"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
            />
            <Button color="red" fluid type="submit">
              ログイン
            </Button>
          </Form>
        </Grid.Column>
        <Grid.Column style={{ padding: "10% 5%" }}>
          <Signup />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  authenticated: state.accounts.authenticated,
});

export default connect(mapStateToProps, { login })(Login);
