import React from "react";
import InfoView from "../components/InfoView";
import { Message, Segment, Header } from "semantic-ui-react";
import Red from "../components/Red";

const Notice = () => (
  <InfoView header="買取時のご注意事項">
    <Message
      negative
      list={[
        "法人様、業者様、大口持ちのお客様はご相談に応じますのでこちら080-4613-2606までご連絡下さい。",
        "買取相場は毎日変動するため下記価格は、あくまで買取参考価格です。下記価格での買取を保証するものではありません（その時の最新価格を常に更新しています）。",
      ]}
    />
    <Segment>
      <Header
        as="h4"
        icon="asterisk"
        content="買取依頼にて商品をご発送される前に以下の点につきまして、ご確認お願いいたします。"
        color="red"
      />
      <Header as="h4" icon="exclamation circle" content="白ロム状態にする" />
      <p>必ず携帯端末からSIMカードを抜いた白ロム状態にしてください。</p>
      <Header as="h4" icon="exclamation circle" content="メモリーを削除する" />
      <p>
        本体メモリーを全て消去してください。
        <br />
        <Red>
          Apple ID や Apple iCloud の設定を行っている場合は必ず削除・リセット
        </Red>
        をお願い致します。
        <br />
        <Red>iPhoneはアクティベーションの設定</Red>
        をしてからお持ちください。
      </p>
      <Header
        as="h4"
        icon="exclamation circle"
        content="暗証番号を初期設定に戻す"
      />
      <p>
        必ず本体の暗証番号を初期設定にしてください。
        <br />
        NTTドコモ：0000
        <br />
        au：1234
        <br />
        ソフトバンク：9999
        <br />
        Yモバイル：9999
        <br />
        ※
        初期暗証番号が無い機種や違う機種もございますので詳しくは各キャリアにお問い合わせください。
        <br />※
        オールリセットや初期化を行っても暗証番号は初期設定にならない機種もございます。
      </p>
      <Header
        as="h4"
        icon="exclamation circle"
        content="ICアプリ・生活アプリは削除してください"
      />
      <p>
        ※
        登録又はご使用されたおサイフケータイ等のICアプリ・生活アプリは、登録したSIMカードを挿入してからでないと消去出来ませんので、必ずお客様にて手動での削除をお願い致します。（本体初期化やオールリセット等をしても、削除出来ませんのでご注意ください。）
      </p>
      <Header
        as="h4"
        icon="exclamation circle"
        content="故障・水濡れや動作不良がないかご確認"
      />
      <p>
        故障・水濡れ（水濡れシールの反応）や動作不良がある場合は、買取出来ません。
        <br />
        下記の商品については、一度ご相談ください。
      </p>
      <Header
        as="h4"
        icon="exclamation circle"
        content="一部が破損または紛失している携帯電話"
      />
      <p>
        「ケータイ補償お届けサービス」・「ケータイ安心パック（おまかせロック）」・「基本オプションパック」・あんしん保証パック」等の、有料サービスを契約されている場合は、必ずオプションの解約をしてください。当店との売買成立後に、不正利用（盗難品やおまかせロック等の遠隔ロック）が発覚した場合、警察への被害届け及び損害賠償請求を行うことがあります。
      </p>
      <Header
        as="h4"
        icon="asterisk"
        content="上記SIMカードの外し忘れ、初期化の忘れ等において万が一、不足の事態が起きても当社では一切の責任を負いかねます。"
        color="red"
      />
    </Segment>
  </InfoView>
);

export default Notice;
