import axios from "axios";
import { dispatchSuccess, dispatchError } from "./messages";
import { getFormData, tokenConfig } from "./helpers";

const msg = {
  unsettle: { logout: "Successfully logged out." },
  create: { created: "Successfully signed up." },
  update: { updated: "Successfully updated." },
  destroy: { deleted: "Successfully deleted." },
};

export const list = (url, success, error, loading) => (dispatch) => {
  dispatch({ type: loading });
  axios
    .get(url)
    .then((res) => {
      // Update with data without messages
      dispatchSuccess(dispatch, null, success, res.data);
    })
    .catch((err) => dispatchError(dispatch, err, error));
};

export const email = (url, success, error, data, msg) => (dispatch) => {
  axios
    .post(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => dispatchSuccess(dispatch, msg, success))
    .catch((err) => dispatchError(dispatch, err, error));
};

export const authenticate = (url, success, error, data) => (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  axios
    .post(url, JSON.stringify(data), config)
    .then((res) => dispatchSuccess(dispatch, null, success, res.data))
    .catch((err) => dispatchError(dispatch, err, error));
};

export const unsettle = (url, success, error) => (dispatch, getState) => {
  axios
    .post(url, null, tokenConfig(getState))
    .then((res) => dispatchSuccess(dispatch, msg.unsettle, success))
    .catch((err) => dispatchError(dispatch, err, error));
};

export const retrieve = (url, success, error) => (dispatch, getState) => {
  axios
    .get(url, tokenConfig(getState))
    .then((res) => dispatchSuccess(dispatch, null, success, res.data))
    .catch((err) => dispatchError(dispatch, err, error));
};

export const upload = (url, data, success, error, nxt = null) => (
  dispatch,
  getState
) => {
  axios
    .post(url, getFormData(data), tokenConfig(getState, true))
    .then((res) => {
      dispatchSuccess(dispatch, msg.create, success);
      if (nxt) dispatch(nxt());
    })
    .catch((err) => dispatchError(dispatch, err, error));
};
