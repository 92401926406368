// PAGE URLS
export const HOSTNAME = "";
export const HOME = `${HOSTNAME}/`;
export const ABOUT = `${HOSTNAME}/about`;
export const ELECTRONICS = `${HOSTNAME}/electronics`;
export const COSMETICS = `${HOSTNAME}/cosmetics`;
export const MEDICINE = `${HOSTNAME}/medicine`;
export const ALCOHOL = `${HOSTNAME}/alcohol`;
export const WAREHOUSE = `${HOSTNAME}/warehouse`;
export const WALKIN = `${HOSTNAME}/walkin`;
export const MAIL = `${HOSTNAME}/mail`;
export const ORGANIZATION = `${HOSTNAME}/organization`;
export const TERMS = `${HOSTNAME}/terms`;
export const NOTICE = `${HOSTNAME}/notice`;
export const CONTACT = `${HOSTNAME}/contact`;
export const LOGIN = `${HOSTNAME}/login`;
export const LOGO = process.env.PUBLIC_URL + "/logo192.png";
export const INTWMS = "/int-wms";
export const DASHBOARD = "/lala-admin";
export const CUSTOMER_DASHBOARD = "/customer-dashboard";

// CHECK LOCATION
export const checkLoation = (url) => {
  switch (url) {
    case HOME:
    case ABOUT:
    case ELECTRONICS:
    case WALKIN:
    case MAIL:
    case ORGANIZATION:
    case TERMS:
    case NOTICE:
    case CONTACT:
    case LOGIN:
    case CUSTOMER_DASHBOARD:
      return 0; // Lala-mobile, single-address
    case WAREHOUSE:
    case COSMETICS:
      return 1; // Lala-shop, multi-address
    case MEDICINE:
    case ALCOHOL:
      return 2; // Lala-shop, single-address
    case INTWMS:
    case DASHBOARD:
      return 200;
    default:
      return 404;
  }
};
