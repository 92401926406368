import React from "react";
import PropTypes from "prop-types";
import { Input, Icon } from "semantic-ui-react";

const SearchBox = ({ clickEvent, keyword, setKeyword }) => (
  <Input
    icon={
      <Icon
        name="search"
        inverted
        circular
        link
        color="red"
        onClick={clickEvent}
      />
    }
    size="small"
    placeholder="商品名・JAN…"
    fluid
    value={keyword}
    onChange={(e) => setKeyword(e.target.value)}
    style={{ marginBottom: "15px" }}
  />
);

SearchBox.propTypes = {
  clickEvent: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func.isRequired,
};

export default SearchBox;
