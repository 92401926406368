import React from "react";
import { List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  ALCOHOL,
  COSMETICS,
  ELECTRONICS,
  HOME,
  MEDICINE,
} from "../common/urls";

const BuyInMenu = () => (
  <List.List>
    <List.Item as={Link} to={HOME}>
      携帯買取価格スピード査定
    </List.Item>
    <List.Item as={Link} to={ELECTRONICS}>
      家電製品買取価格スピード査定
    </List.Item>
    <List.Item as={Link} to={COSMETICS}>
      日用品買取価格スピード査定
    </List.Item>
    <List.Item as={Link} to={MEDICINE}>
      医療品買取価格スピード査定
    </List.Item>
    <List.Item as={Link} to={ALCOHOL}>
      お酒買取価格スピード査定
    </List.Item>
  </List.List>
);

export default BuyInMenu;
