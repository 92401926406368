import React from "react";
import PropTypes from "prop-types";

const Red = ({ children }) => <span style={{ color: "red" }}>{children}</span>;

Red.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Red;
