import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { listElectronics } from "../redux/actions/products";
import BasicView from "../components/BasicView";
import { KAITORI_M } from "../common/constants";
import { getFlatArr, getFiltered } from "../common/utils";
import SearchBox from "../components/SearchBox";
import SideMenu from "../components/SideMenu";
import ScrollList from "../components/ScrollList";

const Electronics = ({ electronics, electronicsListing, listElectronics }) => {
  useEffect(listElectronics, []);
  const [activeIdx, setIdx] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const arr = getFlatArr(electronics, 3);
  const menuItems = electronics.map((c) => ({
    id: c.id,
    text: c.name,
    sub: c.brands.map((b) => ({
      id: b.id,
      text: b.name,
      num: b.products.length,
    })),
  }));
  const recommended = arr.filter((p) => p.recommended);
  const brands = electronics.map((c) => c.brands).flat();

  return (
    <BasicView
      pageIdx={1}
      kaitori={KAITORI_M}
      itemListing={electronicsListing}
      queryProps={{ levels: 3, tree: electronics }}
      nodeSearch={
        <SearchBox
          clickEvent={() => setIdx(-2)}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      }
      nodeMenu={
        <SideMenu
          activeIdx={activeIdx}
          setIdx={setIdx}
          num0={recommended.length}
          items={menuItems}
          type="TREE"
        />
      }
      nodeBoard={
        activeIdx === -2 ? (
          <ScrollList
            header="商品検索"
            body={getFiltered(arr, keyword)}
            icon="search"
            empty="ご指定の検索条件に該当する商品はみつかりませんでした"
          />
        ) : activeIdx === -1 ? (
          <ScrollList header="強化買取" body={recommended} icon="thumbs up" />
        ) : (
          <ScrollList
            header={brands.find((b) => activeIdx === b.id).name}
            body={brands.find((b) => activeIdx === b.id).products}
            icon="laptop"
          />
        )
      }
    />
  );
};

Electronics.propTypes = {
  listElectronics: PropTypes.func.isRequired,
  electronics: PropTypes.array.isRequired,
  electronicsListing: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  electronics: state.products.electronics,
  electronicsListing: state.products.electronicsListing,
});

export default connect(mapStateToProps, { listElectronics })(Electronics);
